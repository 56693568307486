body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#divMenuLandingPage {
  background-color: #A32927;
}

.caixaLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* margin-top: -5rem !important; */
}

#divMenu {
  background-color: #A32927 !important;
  justify-content: space-between;
  margin-bottom: 10px;
}

.tituloDashBoard {
  color: #A32927;
}

.bemVindo {
  color: #fff;
  border-color: #fff;
  margin-right: 30px;
  padding: 7px;
}

.activeItemMenu,
.bemVindo {
  background-color: #b03034;
  border-radius: 5px;
}

.container {
  padding-right: 0;
  padding-left: 0;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f9c647;
}

.App {
  padding: 10px;
}

.navbar-dark .navbar-nav .nav-link {
  font-size: 20px;
}

footer {
  background-color: #a32927;
}

.display-3 {
  font-size: 3.5rem;
}

.listMaterias {
  background-color: #f6c20d;
  display: flex;
  padding: 6px;
  margin-bottom: 2px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  z-index: 9999;
  padding-left: 10px;
  padding-right: 30px;
  color: #303030;
  border-radius: 5px;
}

.dificuldade {
  height: 288px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}

.div1 {
  transform: rotate(270deg);
  text-align: center;
  font-weight: bold;
  display: inline-flex;
}

.div2 {
  transform: rotate(270deg);
  text-align: center;
  font-weight: bold;
  display: inline-flex;
}

#icone {
  position: relative;
  top: 5px;
  display: block;
  width: 18px;
  height: 11px;
  opacity: .25;
  margin-right: 20px;
  cursor: row-resize;
  background: -webkit-linear-gradient(top, #000, #000 20%, #fff 0, #fff 40%, #000 0, #000 60%, #fff 0, #fff 80%, #000 0, #000);
  background: linear-gradient(180deg, #000, #000 20%, #fff 0, #fff 40%, #000 0, #000 60%, #fff 0, #fff 80%, #000 0, #000);
}

.titulosPrincipais {
  margin-bottom: 2rem;
}

.faq h1 {
  margin-bottom: 1rem;
  color: #a32927;
}

.faq p {
  margin-left: 2%;
}

.linkEsqueciSenha, .linkEsqueciSenha:hover {
  color: #dc3545;
}